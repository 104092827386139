import { v4 as uuidv4 } from 'uuid';
import { LOCAL_STORAGE_KEYS } from './constants';

// TODO: review and standardize browser logic.
const isBrowser = typeof window !== 'undefined' && typeof window.localStorage !== 'undefined';

let inMemoryStorage = {};

const getLocalStorageItem = (key) => {
	if (isBrowser) {
		return localStorage.getItem(key);
	} else {
		return inMemoryStorage[key] || null;
	}
};

const setLocalStorageItem = (key, value) => {
	if (isBrowser) {
		localStorage.setItem(key, value);
	} else {
		inMemoryStorage[key] = value;
	}
};

export const getDeviceId = () => {
	let savedDeviceId = '';

	if (isBrowser) {
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has('device_id')) {
			savedDeviceId = searchParams.get('device_id');
			setLocalStorageItem(LOCAL_STORAGE_KEYS.DEVICE_ID, savedDeviceId);
		} else {
			savedDeviceId = getLocalStorageItem(LOCAL_STORAGE_KEYS.DEVICE_ID);
		}
	} else {
		savedDeviceId = getLocalStorageItem(LOCAL_STORAGE_KEYS.DEVICE_ID);
	}

	if (savedDeviceId) {
		return savedDeviceId;
	}

	const deviceId = uuidv4();
	setLocalStorageItem(LOCAL_STORAGE_KEYS.DEVICE_ID, deviceId);

	return deviceId;
};
