import * as Sentry from '@sentry/browser';

/**
 * @typedef {object} Condition
 * @property {(config: import('$lib/stores/app/store').AppStore) => Promise<boolean>} condition - A function that returns a boolean indicating if the condition is met.
 * @property {() => Promise<any>} resolve - A function that returns a promise which resolves to a module.
 */

/**
 * Resolves the condition based on the provided conditions object.
 * @param {{[key: string]: Condition}} conditions - The conditions object containing the conditions to be resolved.
 * @param {import('$lib/stores/app/store').AppStore} appMeta information about the app instance
 * @returns {Promise<{ [key: string]: typeof import('svelte').SvelteComponent }>} A promise that resolves to a Svelte component module.
 */
export const resolveCondition = async (conditions, appMeta) => {
	let defaultCondition;

	for (const key in conditions) {
		if (key === 'default') {
			defaultCondition = conditions[key];
			continue;
		}

		const conditionObj = conditions[key];
		if (conditionObj.condition && (await conditionObj.condition(appMeta))) {
      Sentry.addBreadcrumb({
        category: 'resolve-page-condition',
        message: `Non-default condition: ${key}`,
        data: {
          conditions,
          appMeta
        },
        level: 'info'
      });
			return await conditionObj.resolve();
		}
	}

	if (defaultCondition) {
    Sentry.addBreadcrumb({
      category: 'resolve-page-condition',
      message: `Default condition`,
      data: {
        conditions,
        appMeta
      },
      level: 'info'
    });
		return await defaultCondition.resolve();
	}

	throw new Error('No conditions met and no default condition found');
};
