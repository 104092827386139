export const trackedElems = ['BUTTON', 'A', 'SELECT'];

export const getJSPath = (element) => {
	if (element.id !== '') {
		return `document.getElementById('${element.id}')`;
	}
	const path = [];
	let currentElement = element;
	while (currentElement.tagName !== 'HTML') {
		let selector = currentElement.tagName.toLowerCase();
		if (currentElement.id) {
			selector += `#${currentElement.id}`;
			path.unshift(selector);
			break; // ID is unique, no need to go further
		}
		const siblingIndex =
			Array.from(currentElement?.parentNode?.children).indexOf(currentElement) + 1;
		selector += `:nth-child(${siblingIndex})`;
		path.unshift(selector);
		currentElement = currentElement.parentNode;
	}
	return `document.querySelector('${path.join(' > ')}')`;
};

export const hashElementPath = async (elementPath) => {
	const encoder = new TextEncoder();
	const data = encoder.encode(elementPath);
	const hashBuffer = await crypto.subtle.digest('SHA-256', data);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
	return hashHex;
};

export const isCustomTrackedElement = (element) => {
	while (element && element !== document) {
		if (element.hasAttribute('data-track-id')) {
			return element.getAttribute('data-track-id');
		}
		element = element.parentElement;
	}
	return null;
};
