const SUBDOMAIN_REGEX = /.*\.(.*?\.(?:com|net))/;

/**
 *
 * @param {string} hostname - The hostname from which to extract the domain. Defaults to the current location's hostname.
 * @returns {string} The extracted domain if a match is found; otherwise, returns the original hostname.
 */
function getDomain(hostname = window.location.hostname) {
  const matches = SUBDOMAIN_REGEX.exec(hostname);
  if (matches === null) {
    return hostname;
  }

  return matches[1];
};

export const SECURE = typeof window !== 'undefined' && window.location.protocol === 'https:';
export const DOMAIN = typeof window !== 'undefined' ? getDomain(window.location.hostname) : '.abcmouse.com';
