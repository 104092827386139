/**
 *  @type {{[key: string]: import('$lib/utils/resolve-condition').Condition}} conditions - The conditions object containing the conditions to be resolved.
 */
const pageConditions = {
	default: {
		condition: async () => true,
		resolve: async () => await import('../_browser/Page.svelte')
	}
};

export { pageConditions };
