<script>
	import { onMount } from 'svelte';
	import { resolveCondition } from '$lib/utils/resolve-condition';
	import { pageConditions } from './__config/conditions';
	import { app } from '$lib/stores/app/store';
	export let data;

	/**
	 * @type {typeof import('svelte').SvelteComponent}
	 */
	let Component; //TODO: NEED TO THINK ABOUT BEST APPROACH FOR THIS FOR SSR

	onMount(async () => {
		const module = await resolveCondition(pageConditions, $app);
		Component = module.default;
	});
</script>

{#if Component}
	<svelte:component this={Component} pageData={data} />
{/if}
